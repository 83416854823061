import { useTableData } from '../context/TableData'
import moment from 'moment'

const Table = () => {
	const { data } = useTableData()

	const renderRowData = item => {
		return (
			<tr key={item.id}>
				<td className="px-6 py-4">
					<div className="text-sm text-gray-500">{item.id}</div>
				</td>

				<td className="px-6 py-4">
					<div className="text-sm text-gray-500">{moment(item.date).format('DD/MM/YYYY HH:mm:ss')}</div>
				</td>

				<td className="px-6 py-4">
					<div className="text-sm text-gray-500" dangerouslySetInnerHTML={{ __html: item.message }}></div>
				</td>
			</tr>
		)
	}

	const renderTH = (name, index) => {
		return <th
			key={index}
			scope="col"
			className="
				px-6
				py-3
				text-left
				text-xs
				font-medium
				text-gray-500
				uppercase
				tracking-wider
			"
		>
			{name}
		</th>
	}

	if (!data || !data.length) {
		return <></>
	}

	return (
		<div className="flex">
			<div className="py-6 align-middle inline-block min-w-full sm:px-6 lg:px-8">
				<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
					<table className="min-w-full divide-y divide-gray-200">
						<thead className="bg-gray-50">
							<tr>
								{['', 'Data', 'Status'].map((item, index) => renderTH(item, index))}
							</tr>
						</thead>

						<tbody className="bg-white divide-y divide-gray-200">
							{data.map(item => renderRowData(item))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export default Table