import { useState } from 'react'
import axios from 'axios'
import { useTableData } from '../context/TableData'
import { useToast } from '../context/Toast'

const Form = () => {

    const { setData } = useTableData()
    const { setShow, setMessage, setType } = useToast()
    const [loading, setLoading] = useState(false)
    const [nf, setNf] = useState('')
    const [client, setClient] = useState('')

    const track = async () => {
        const company = window.location.pathname.split('/')[1]
        
        if (!company) {
            setShow(true)
            setMessage('Empresa inválida!')
            setType('error')
            return
        }

        if (!nf || !client) {
            setShow(true)
            setMessage('Todos os campos são obrigatórios!')
            setType('error')
            return
        }

        const url = `https://api.frotix.com.br/public/checkdelivery/${nf}/${client}/${company}`

        try {
            setLoading(true)
            
            const { data } = await axios.get(url)

            if (data.success) {
                if (!data.data.rows.length) {
                    setShow(true)
                    setMessage('NF não encontrada!')
                    setType('error')
                    setData([])

                    return
                }

                setData(data.data.rows)
            }
        } catch (error) {
            console.log('[ERROR-TRACKING] => ', error)
        } finally {
            setLoading(false)
        }
    }

    const clear = () => {
        setData([])
        setClient('')
        setNf('')
    }

    return (
        <div className="min-h-full flex items-center justify-center py-4 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <form className="mt-8 space-y-6">
                    <div>
                        <div className="mb-4">
                            <label
                                htmlFor="nf"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Nota Fiscal
                            </label>
                            
                            <input
                                id="nf"
                                name="nf"
                                type="number"
                                required
                                className="
                                    appearance-none
                                    rounded-none
                                    relative
                                    block
                                    w-full
                                    px-3
                                    py-2
                                    border
                                    border-gray-300
                                    placeholder-gray-500
                                    text-gray-900
                                    rounded-t-md
                                    rounded-b-md
                                    focus:outline-none
                                    focus:ring-yellow
                                    focus:border-yellow
                                    focus:z-10
                                    sm:text-sm
                                "
                                placeholder="Nota Fiscal"
                                disabled={loading}
                                value={nf}
                                onChange={e => setNf(e.target.value)}
                            />
                        </div>

                        <div>
                            <label
                                htmlFor="password"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Cliente
                            </label>
                            
                            <input
                                id="client"
                                name="client"
                                type="number"
                                required
                                className="
                                    appearance-none
                                    rounded-none
                                    relative
                                    block
                                    w-full
                                    px-3
                                    py-2
                                    border
                                    border-gray-300
                                    placeholder-gray-500
                                    text-gray-900
                                    rounded-t-md
                                    rounded-b-md
                                    focus:outline-none
                                    focus:ring-yellow
                                    focus:border-yellow
                                    focus:z-10
                                    sm:text-sm
                                "
                                placeholder="Cliente"
                                disabled={loading}
                                value={client}
                                onChange={e => setClient(e.target.value)}
                            />
                        </div>
                    </div>

                    <button
                        type="button"
                        className="
                            group
                            relative
                            w-full
                            flex
                            justify-center
                            py-2
                            px-4
                            border
                            border-transparent
                            text-sm
                            font-medium
                            rounded-md
                            text-white
                            focus:outline-none
                            shadow
                        "
                        style={{ backgroundColor: '#FFC107', color: '#212121' }}
                        onClick={track}
                        disabled={loading}
                    >
                        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                            {!loading &&
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"
                                    />

                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
                                    />
                                </svg>
                            }

                            {loading &&
                                <svg
                                    className="animate-spin -ml-1 mr-3 h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    />

                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    />
                                </svg>
                            }
                        </span>

                        {loading && 'RASTREANDO ...'}

                        {!loading && 'RASTREAR'}
                    </button>

                    <button
                        type="button"
                        className="
                            w-full
                            flex
                            justify-center
                            py-2
                            px-4
                            border
                            border-gray-400
                            text-sm
                            font-medium
                            rounded
                            shadow
                            text-gray-800
                            focus:outline-none
                        "
                        onClick={clear}
                        disabled={loading}
                        style={{ marginTop: 12 }}
                    >
                        LIMPAR
                    </button>

                    <div className='flex justify-center'>
                        <a
                            href="https://www.frotix.com.br"
                            target={'_blank'}
                            className="no-underline hover:underline"
                            style={{ color: '#212121' }}
                        >
                            Sobre a Frotix
                        </a>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Form