import { useEffect, useState } from 'react'
import { useTableData } from '../context/TableData'
import moment from 'moment'
import axios from 'axios'

const TableWithPhoto = () => {
	const { data, setData } = useTableData()
	const [currentIndex, setCurrentIndex] = useState(0);
  const [showGallery, setShowGallery] = useState(false);
	const [images, setImages] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		(async () => {
			try {
				setLoading(true)

				const { data: deliveries } = await axios({
					method: 'get',
					url: `https://api.frotix.com.br/public/checkdelivery${window.location.pathname}`
				})

				setData(deliveries.data.rows)
			} catch (error) {
				console.log('[ERROR-TABLE-WITH-PHOTO] => ', error)
			} finally {
				setLoading(false)
			}
		})()
	}, [])

	function handlePrevImage() {
    if (currentIndex === 0) {
      setCurrentIndex(images.length - 1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  }

  function handleNextImage() {
    if (currentIndex === images.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  }

  const handleDownloadImage = () => {
    const imageUrl = images[currentIndex].src
		const imageName = images[currentIndex].alt

		const link = document.createElement('a');
		link.href = imageUrl;
		link.target = '_blank'
		link.setAttribute('download', imageName);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
  }

	const showPhotos = photos => {
		const mapped = photos.map(item => ({
			src: item.url,
			alt: item.name
		}))

		setImages(mapped)
		setShowGallery(true)
	}

	const renderRowData = item => {
		const hasPhoto = item.photos.length

		return (
			<tr key={item.id}>
				<td className="px-6 py-4 whitespace-nowrap">
					<div className="text-sm text-gray-500">{item.status.id}</div>
				</td>

				<td className="px-6 py-4 whitespace-nowrap">
					<div className="text-sm text-gray-500">{moment(item.statusChangedAt.date).format('DD/MM/YYYY HH:mm:ss')}</div>
				</td>

				<td className="px-6 py-4 whitespace-nowrap">
					<div className="text-sm text-gray-500">{item.status.name}</div>
				</td>

				<td className="px-6 py-4 whitespace-nowrap text-center">
					<div className="text-sm text-gray-500">
						<button
							className={`
								bg-gray-300
								hover:bg-gray-400
								text-gray-800
								font-bold
								py-2
								px-4
								inline-flex
								items-center
								rounded-full
								shadow
								disabled
								${!hasPhoto ? 'disabled' : ''}
							`}
							style={{ backgroundColor: !hasPhoto ? '#757575' : '#FFC107', color: '#212121' }}
							onClick={() => !hasPhoto ? {} : showPhotos(item.photos)}
						>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
								<path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z" />
								<path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z" />
							</svg>
						</button>
					</div>
				</td>
			</tr>
		)
	}

	const renderTH = (name, index) => {
		return <th
			key={index}
			scope="col"
			className="
				px-6
				py-3
				text-left
				text-xs
				font-medium
				text-gray-500
				uppercase
				tracking-wider
			"
		>
			{name}
		</th>
	}

	if (!data || !data.length && !loading) {
		return <div
			style={{
				display: 'flex',
				flex: 1,
				justifyContent: 'center',
				marginTop: 50
			}}
			>
				<h1 style={{ fontSize: 36 }}><em>Nenhum registro encontrado!</em></h1>
			</div>
	}

	return (
		<div
			className="flex items-center"
			style={{
				height: '80vh'
			}}
		>
			<div className="py-6 align-middle inline-block min-w-full sm:px-6 lg:px-8 ">
				<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
					{showGallery && (
						<div className="fixed z-10 top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-97">
							<div className="relative">
								<img
									src={images[currentIndex].src}
									alt={images[currentIndex].alt}
									className="w-full object-cover"
								/>

								<div className="w-full h-full flex flex-row justify-center items-center">
									<button
										type="button"
										className="
											bg-white
											mt-10
											mr-5
											rounded-md
											p-2
											inline-flex
											items-center
											justify-center
											hover:text-gray-500
											hover:bg-gray-100
											focus:outline-none
											focus:ring-2
											focus:ring-yellow
											focus:ring-yellow
										"
										style={{ color: '#212121' }}
										onClick={() => setShowGallery(false)}
									>
										<span className="sr-only">Close menu</span>
										<svg className="h-6 w-6 color-red" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
											<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
										</svg>
									</button>

									<button
										className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 mt-10 rounded inline-flex items-center"
										style={{ backgroundColor: '#FFC107', color: '#212121' }}
										onClick={handleDownloadImage}
									>
										<svg
											className="fill-current w-4 h-4 mr-2"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 20 20"
										>
											<path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/>
										</svg>
										
										<span>Download</span>
									</button>
								</div>
							</div>

							<div className="absolute top-0 bottom-0 left-0 w-12 flex justify-center items-center">
								<button
									style={{ backgroundColor: '#FFC107', color: '#212121' }}
									className="text-white py-2 px-4 rounded hover:bg-gray-700 font-bold"
									onClick={handlePrevImage}
								>
									&lt;
								</button>
							</div>

							<div className="absolute top-0 bottom-0 right-0 w-12 flex justify-center items-center font-bold">
								<button
									style={{ backgroundColor: '#FFC107', color: '#212121' }}
									className="text-white py-2 px-4 rounded hover:bg-gray-700"
									onClick={handleNextImage}
								>
									&gt;
								</button>
							</div>
						</div>
					)}

					<table className="min-w-full divide-y divide-gray-200">
						<thead className="bg-gray-50">
							<tr>
								{['', 'Data', 'Status', ''].map((item, index) => renderTH(item, index))}
							</tr>
						</thead>

						<tbody className="bg-white divide-y divide-gray-200">
							{data.map(item => renderRowData(item))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export default TableWithPhoto