import { useState, useEffect } from 'react'

import Nav from './components/Nav'
import Form from './components/Form'
import Table from './components/Table'
import TableWithPhoto from './components/TableWithPhoto'
import Toast from './components/Toast'

import TableDataProvider from './context/TableData'
import ToastProvider from './context/Toast'

function App() {
  const [isPhotos, setIsPhotos] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    try {
      setLoading(true)

      setIsPhotos(window.location.pathname.split('/').filter(Boolean).length === 5)
    } catch (error) {
      
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <>
      <TableDataProvider>
        <ToastProvider>
          <Nav />

          {!isPhotos && !loading &&
            <>
              <Form />
              <Table />
            </>
          }

          {isPhotos && <TableWithPhoto />}

          <Toast />
        </ToastProvider>
      </TableDataProvider>
    </>
  )
}

export default App