import { createContext, useContext, useState } from "react";

const TableDataContext = createContext()

export default function TableDataProvider({ children }) {
    const [data, setData] = useState([])

    return <TableDataContext.Provider
            value={{ data, setData }}
        >
            {children}
        </TableDataContext.Provider>
}

export function useTableData() {
    const context = useContext(TableDataContext)

    const { data, setData } = context

    return { data, setData }
}