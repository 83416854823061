import { createContext, useContext, useEffect, useState } from "react";

const Toast = createContext()

export default function ToastProvider({ children }) {
    const [show, setShow] = useState(false)
    const [message, setMessage] = useState('')
    const [type, setType] = useState('')

    useEffect(() => {
        if (show) {
            setTimeout(() => {
                setShow(false)
            }, 5000);
        }
    }, [show])

    return <Toast.Provider
            value={{
                show,
                setShow,
                message,
                setMessage,
                type,
                setType
            }}
        >
            {children}
        </Toast.Provider>
}

export function useToast() {
    const context = useContext(Toast)

    const { show, setShow, message, setMessage, type, setType } = context

    return { show, setShow, message, setMessage, type, setType }
}