import icon from '../assets/images/icon.png'

const Nav = () => {
  return (
    <nav
      className="
        flex
        items-center
        justify-between
        flex-wrap
        p-6
      "
      style={{ backgroundColor: '#212121' }}
    >
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <a href="https://www.frotix.com.br" target={'_blank'} className="flex items-center no-underline text-white">
          <img className="mx-auto h-8 w-auto" src={icon} />

          <span className="font-semibold text-xl tracking-tight ml-5" style={{ color: '#BDBDBD' }}>Frotix | Rastrear Entrega</span>
        </a>
      </div>
    </nav>
  )
}

export default Nav